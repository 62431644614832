import React from 'react';
import Navbar from './components/Navbar';
import Start from './components/Start';
import About from './components/About';
import WhyWe from './components/WhyWe';
import Offer from './components/Offer';
import Gallery from './components/Gallery';
import Contact from './components/Contact';
import PageSection from './components/Section';

import {createTheme, ThemeProvider} from '@mui/material';

// Create a custom theme with your typography settings
const theme = createTheme({
    typography: {
        // fontFamily: 'Arial, sans-serif', // Change the font family as needed
        h1: {
            fontSize: '2.5rem', // Customize the font size for h1
            fontWeight: 600,   // Customize the font weight for h1
            color: '#4f4f4f',
        },
        h2: {
            color: '#4f4f4f',
            fontFamily: 'Montserrat, sans-serif',
            fontWeight: 600,
            lineHeight: '1.4em',
            fontSize: '1.4em',
        },
        body1: {
            fontSize: '1rem',  // Customize the font size for body text
        },
    },
    breakpoints: {
        values: {
            mobile: 0,
            tablet: 768,
            desktop: 1300,
        },
    },
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Navbar/>
            <Start/>
            <PageSection id="About"><About/></PageSection>
            {/*<PageSection id="WhyWe"><WhyWe/></PageSection>*/}
            <PageSection id="WhyWe"><WhyWe/></PageSection>
            <PageSection id="Offer"><Offer/></PageSection>
            <PageSection sx={{backgroundColor: "#efefef"}} id="Contact"><Contact/></PageSection>
            <PageSection id="Gallery"><Gallery/></PageSection>
        </ThemeProvider>
    );
}

export default App;
