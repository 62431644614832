import React from 'react';
import {Grid, Paper, styled, Typography} from '@mui/material';

const GalleryContainer = styled(Grid)({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: '50px',
});

const GalleryImage = styled(Paper)({
    flex: '1 0 calc(33.33% - 16px)',
    minWidth: '200px',
    boxSizing: 'border-box',
    boxShadow: 'none',

    '& img': {
        width: '100%',
        height: 'auto',
        display: 'block',
    },
});

const Gallery = () => {
    const imageContext = require.context('../assets/images/gallery', false, /\.(png|jpe?g|svg)$/);
    const images = imageContext.keys().map(imageContext);

    return (
        <>
            <Typography variant="h1">Galeria</Typography>
            <GalleryContainer container>
                {images.map((imageUrl, index) => (
                    <GalleryImage key={index}>
                        <img src={imageUrl} alt={`Image ${index}`}/>
                    </GalleryImage>
                ))}
            </GalleryContainer>
        </>
    );
};

export default Gallery;
