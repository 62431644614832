import {Container} from "@mui/material";
import {styles} from './Styles'

const PageSection = (props) => {
    const {children, sx, id} = props
    return (
        <Container
            sx={{...styles.pageSection, ...sx}}
            id={id}
        >
            {children}
        </Container>
    );
};

export default PageSection