export const styles = {
    toolbar: {
        root: {
            display: {mobile: 'none', tablet: 'flex', desktop: 'flex'},
            justifyContent: 'space-between',
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
        },
        mobile: {
            display: {mobile: 'flex', tablet: 'none', desktop: 'none'}
        },
    },
    scrollLink: {
        cursor: 'pointer',
        borderBottom: '2px solid transparent',
        boxShadow: 'none',
        '&:hover': {
            borderBottom: '2px solid white',
            boxShadow: '0px 3px 2px -3px #fff',
        },
        '&.active': {
            borderBottom: '2px solid white',
            boxShadow: '0px 3px 2px -3px #fff',
        },
    },
    pageSection: {
        padding: {
            desktop: '4em 10em',
            tablet: '4em 5em',
            mobile: '2em',
        },
        maxWidth: 'desktop',
    }
}