import React from 'react';
import {Box, Grid, Tab, Tabs, Typography} from "@mui/material";
import PropTypes from 'prop-types';
import Image from "../assets/images/im2.png";


const CustomTabPanel = (props) => {
    const {children, value, index, title, subtitle, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography variant="h5" component="p" sx={{ minHeight: '2em' }}>{title}</Typography>
                    <Typography variant="h6" component="p" sx={{marginY: 1.5, minHeight: '2em'}}>{subtitle}</Typography>
                    {children}
                </Box>
            )}
        </div>
    );
}

const tabs = [
    {
        label: "Nasza misja",
        title: "NA RYNKU OD BLISKO 20 LAT",
        subtitle: "DOŚWIADCZENIE I PASJA",
        content: (
            <>
                <Typography variant="body1" sx={{marginY: 2}}>
                    Niezmiennie, od początku naszej działalności naszym celem jest maksymalne zadowolenie klienta.
                </Typography>
                <Typography variant="body1">
                    Do każdego zlecenia podchodzimy indywidualnie, służąc fachowym doradztwem oraz pomocą w doborze optymalnego rozwiązania.
                </Typography>
            </>
        ),
    },
    {
        label: "Twoja wizja",
        title: "TWOJA WIZJA - NASZE WYKONANIE",
        subtitle: "ZADOWOLENIE KLIENTA NASZĄ SATYSFAKCJĄ",
        content: (
            <Typography variant="body1" sx={{marginY: 2}}>
                Niezależnie od tego czy jesteś dużym, wymagającym klientem instytucjonalnym, czy drobnym klientem indywidualnym, nasz fachowy zespół w pełni zaangażuje się w Twoje zlecenie, realizując je terminowo od 'A' do 'Z'.
            </Typography>
        ),
    },
    {
        label: "Wsparcie",
        title: "SERWIS GWARANCYJNY I POGWARANCYJNY",
        subtitle: "PEŁNE WSPRACIE TECHNICZNE",
        content: (
            <Typography variant="body1" sx={{marginY: 2}}>
                Świadczymy usługi kompleksowego serwisu gwarancyjnego oraz pogwarancyjnego dla klientów, którzy zakupili nasze kontenery. Nasi klienci zawsze mogą liczyć na pomoc naszych fachowych i wysoko wykwalifikowanych pracowników.
            </Typography>
        ),
    },
];

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};


const WhyWe = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Grid container spacing={4}>
            <Grid item mobile={12} tablet={6} sx={{display: {mobile: 'none', tablet: 'block'}}}>
                <img src={Image} width="100%"/>
            </Grid>
            <Grid item mobile={12} tablet={6}>
                <Typography variant="h1">Dlaczego my?</Typography>
                <Box sx={{width: '100%'}}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs variant="fullWidth" value={value} onChange={handleChange}>
                            <Tab label="Nasza misja" id='tab-0'/>
                            <Tab label="Twoja wizja" id='tab-1'/>
                            <Tab label="Wsparcie" id='tab-2'/>
                        </Tabs>
                    </Box>
                    {tabs.map((tab, index) => (
                        <CustomTabPanel value={value} index={index} key={index} title={tab.title}
                                        subtitle={tab.subtitle}>
                            {tab.content}
                        </CustomTabPanel>
                    ))}
                </Box>
            </Grid>
        </Grid>
    );
}

export default WhyWe;
