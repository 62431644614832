import React from 'react';
import { Typography, Card, CardContent, Box} from '@mui/material';


const styles = {
    infoGrid: {
        display: 'grid',
        gridTemplateColumns: {
            desktop: `repeat(3, 1fr)`,
            tablet: `repeat(2, 1fr)`,
            mobile: '1fr',
        },
        gap: `2em 4em`,
    },
};

export const Offer = () => {
    // Define an array of card data
    const cardData = [
        {
            title: 'Kontenery biurowe',
            description: 'Idealne dla klientów potrzebujących estetycznej i elastycznej przestrzeni biurowej.',
        },
        {
            title: 'Kontenery mieszkaniowe',
            description: 'Mogą być wykorzystywane w budownictwie mieszkaniowym jedno oraz wielokondygnacyjnym.',
        },
        {
            title: 'Kontenery sanitarne',
            description: 'Łazienki i ubikacje w pełni wyposażone w urządzenia sanitarne. Dzięki zastosowanym materiałom, łatwe w utrzymaniu w czystości.',
        },
        {
            title: 'Obiekty handlowe, kioski',
            description: 'Znajdą zastosowanie w drobnym handlu i przedsiębiorczości. Idealne także dla stróżów nocnych oraz ochrony.',
        },
        {
            title: 'Kontenery magazynowe',
            description: 'Znajdą zastosowanie wszędzie tam, gdzie potrzebna jest przestrzeń magazynowa. Budujemy magazyny wielko i małopowierzchniowe.',
        },
        {
            title: 'Domki letniskowe',
            description: 'Marzysz o niedrogim domku letniskowym bez konieczności uzyskiwania pozwolenia na budowę i innych formalności? To rozwiązanie jest dla Ciebie!',
        },
    ];

    return (
        <>
            {/* Section Header */}
            <Typography variant="h1" sx={{marginTop: 4}}>
                Oferta
            </Typography>

            {/* Section Text */}
            <Typography variant="body1" sx={{marginY: 4}}>
                Niezależnie od przeznaczenia produkowane przez nas budynki modułowe mogą być niezwykle funkcjonalne. W
                ich wnętrzach można dowolnie aranżować przestrzeń wprowadzając meble, sprzęt oraz potrzebne instalacje.
                Wielość rozwiązań oraz indywidualne podejście do każdego klienta pozwalają nam spełnić życzenia i
                potrzeby nawet najbardziej wymagających zleceniodawców.
            </Typography>

            {/* Section Text */}
            <Typography variant="body1" sx={{marginY: 2}}>
                Dużą zaletą naszych produktów jest brak konieczności wykonywania stałych fundamentów jak również łatwość
                montażu oraz demontażu, co umożliwia usytuowanie obiektu w wybranym miejscu w krótkim czasie oraz
                przeniesienie obiektu w inne miejsce. Oferujemy specjalistyczny transport we wskazane miejsce.
            </Typography>

            {/* Grid of Cards */}
            <Box sx={styles.infoGrid}>
                {cardData.map((card, index) => (
                    <Card sx={{height: '100%'}} key={`offer-${index}`}>
                        <CardContent>
                            <Typography variant="h2">{card.title}</Typography>
                            <Typography variant="body2" sx={{marginY: 1}}>{card.description}</Typography>
                        </CardContent>
                    </Card>
                ))}
            </Box>

            <Typography variant="body1" sx={{marginY: 4}}>
                Nie znalazłeś powyżej interesującego Cię produktu? Jesteśmy bardzo elastyczną firmą, która chętnie
                dostosowuje się do potrzeb swoich klientów. Skontaktuj się z nami, przygotujemy rozwiązanie specjalnie
                dla Ciebie!
            </Typography>
        </>
    );
};

export default Offer;