import React from 'react';
import {Link as ScrollLink} from 'react-scroll';
import {styled} from '@mui/system';

const commonScrollLinkProps = {
    spy: true,
    smooth: true,
    offset: -10,
    duration: 500,
};

const StyledScrollLink = styled(ScrollLink)(({theme, active}) => ({
    cursor: 'pointer',
    borderBottom: active ? '2px solid white' : '2px solid transparent',
    boxShadow: active ? '0px 3px 2px -3px #fff' : 'none',
    '&:hover': {
        borderBottom: '2px solid white',
        boxShadow: '0px 3px 2px -3px #fff',
    },
    '&.active': {
        borderBottom: '2px solid white',
        boxShadow: '0px 3px 2px -3px #fff',
    },
}));

const CustomScrollLink = ({to, children}) => {
    return (
        <StyledScrollLink to={to} {...commonScrollLinkProps}>
            {children}
        </StyledScrollLink>
    );
};

export default CustomScrollLink;
