import React from 'react';
import {Box, Container, Grid, Typography, useTheme} from "@mui/material";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

const styles = {
    infoGrid: {
        display: 'grid',
        gridTemplateColumns: {
            desktop: '1fr 4fr',
            tablet: '1fr 5fr',
            mobile: '1fr 6fr',
        },
        gap: `5em 2em`,
    },
    iconElement: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& svg': {
            fontSize: '2em',
            color: '#4f4f4f',
        }
    },
};
const Contact = () => {
    const email = "convert@interia.pl";
    const handleEmailClick = () => {
        window.location.href = `mailto:${email}`;
    };
    return (<>
        <Typography variant="h1" sx={{marginBottom: "1em"}}>Kontakt</Typography>
        <Box sx={styles.infoGrid}>
            <Box sx={styles.iconElement}><LocationOnIcon fontSize="large" color="primary"/></Box>
            <Typography variant="body1">
                A-Z Convert Artur Przechrzta<br/>
                ul. Podleska 11/13<br/>
                43-190 Mikołów
            </Typography>
            <Box sx={styles.iconElement}><PhoneIcon fontSize="large"/></Box>
            <Typography variant="body1">+48 501 135 022</Typography>
            <Box sx={styles.iconElement}><EmailIcon fontSize="large"/></Box>
            <Typography variant="body1" sx={{cursor: 'pointer'}} onClick={handleEmailClick}>
                convert@interia.pl
            </Typography>


        </Box>
        {/*<Grid container spacing={2}>*/}
        {/*    <Grid item mobile={1} sx={{*/}
        {/*        display: "flex",*/}
        {/*        alignItems: 'center'*/}
        {/*    }}>*/}
        {/*        <LocationOnIcon fontSize="large" color="primary"/>*/}
        {/*    </Grid>*/}
        {/*    <Grid item mobile={11}>*/}
        {/*        <Typography variant="body1">*/}
        {/*            A-Z Convert Artur Przechrzta<br/>*/}
        {/*            ul. Podleska 11/13<br/>*/}
        {/*            43-190 Mikołów*/}
        {/*        </Typography>*/}
        {/*    </Grid>*/}
        {/*    <Grid item mobile={1} sx={{*/}
        {/*        display: 'flex',*/}
        {/*        alignItems: 'center'*/}
        {/*    }}>*/}
        {/*        <PhoneIcon fontSize="large" color="primary"/>*/}
        {/*    </Grid>*/}
        {/*    <Grid item mobile={11}>*/}
        {/*        <Typography variant="body1">501 135 022</Typography>*/}
        {/*    </Grid>*/}
        {/*    <Grid item mobile={1} sx={{*/}
        {/*        display: 'flex',*/}
        {/*        alignItems: 'center'*/}
        {/*    }}>*/}
        {/*        <EmailIcon fontSize="large" color="primary"/>*/}
        {/*    </Grid>*/}
        {/*    <Grid item mobile={11}>*/}
        {/*        <Typography variant="body1" sx={{cursor: 'pointer'}} onClick={handleEmailClick}>*/}
        {/*            convert@interia.pl*/}
        {/*        </Typography>*/}
        {/*    </Grid>*/}
        {/*</Grid>*/}
    </>);
}

export default Contact;
