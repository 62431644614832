import React from 'react';
import {Grid, Typography} from "@mui/material";
import Image from '../assets/images/im1.png';

const About = () => {
    return (
        <Grid container spacing={4}>
            <Grid item mobile={12} tablet={6}>
                <Typography variant="h1" component="h1">
                    Produkcja, sprzedaż, montaż oraz wynajem kontenerów.
                </Typography>
                <Typography variant="body1">
                    Firma A-Z Convert zajmuje się produkcją, sprzedażą oraz wynajmem kontenerów. Nasze kontenery można
                    stosować jako pojedyncze, niezależne obiekty, jak również istnieje możliwość realizacji budynków
                    piętrowych oraz dobudowy kolejnych segmentów do obiektów już istniejących. Oferujemy budynki
                    kontenerowe dostosowane do indywidualnych wymagań i potrzeb naszych klientów. Zatrudniamy
                    wykwalifikowaną i doświadczoną kadrę pracowniczą, o czym świadczy wysoki standard wykonywanych przez
                    nas prac, jak również zadowolenie naszych klientów. Nasza firma posiada duży krąg stałych odbiorców.
                </Typography>
            </Grid>
            <Grid item mobile={12} tablet={6}>
                <img src={Image} width="100%" alt="Left"/>
            </Grid>
        </Grid>
    );
}

export default About;
