import React, {useEffect, useState} from 'react';
import {
    AppBar,
    Box,
    Menu,
    MenuItem,
    Stack,
    Toolbar,
    Typography,
} from '@mui/material';
import {Menu as MenuIcon} from '@mui/icons-material';
import LogoImage from '../assets/images/logo.png';
import CustomScrollLink from './CustomScrollLink';
import { styles } from './Styles';

const scrollLinks = [
    {to: 'About', label: 'O firmie'},
    {to: 'WhyWe', label: 'Dlaczego my?'},
    {to: 'Offer', label: 'Oferta'},
    {to: 'Contact', label: 'Kontakt'},
    {to: 'Gallery', label: 'Galeria'},
];

export const Navbar = () => {
    const [open, setOpen] = useState(false);
    const [isTransparent, setIsTransparent] = useState(true);

    useEffect(() => {
        // Add a scroll event listener
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const transparency = Math.min(1, scrollPosition / window.innerHeight);
            setIsTransparent(transparency);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const appBarStyle = {
        backgroundColor: `rgba(0, 0, 0, ${isTransparent / 10})`,
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, ${isTransparent}), transparent)`,
    };

    const menuItemsStyle = {
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
    };

    return (
        <Box sx={{flexGrow: 1}}>
            <AppBar position="fixed" sx={appBarStyle}>
                <Toolbar sx={styles.toolbar.root}>
                    <Box
                        sx={{
                            flex: 1,
                            height: '100%',
                            maxHeight: '100%',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <CustomScrollLink to="Start">
                            <Typography>
                                <img src={LogoImage} height="100%" alt="AZ-Convert Logo"/>
                            </Typography>
                        </CustomScrollLink>
                    </Box>
                    <Stack direction="row" spacing={5} flex={2}>
                        {/* Use CustomScrollLink components for smooth scrolling */}
                        {scrollLinks.map((link) => (
                            <CustomScrollLink key={link.to} to={link.to}>
                                <Typography>{link.label}</Typography>
                            </CustomScrollLink>
                        ))}
                    </Stack>
                </Toolbar>
                <Toolbar sx={[styles.toolbar.root, styles.toolbar.mobile]}>
                    <Box flex={1}>
                        <CustomScrollLink to="Start">
                            <img src={LogoImage} height="100%" alt="AZ-Convert Logo"/>
                        </CustomScrollLink>
                    </Box>
                    <MenuIcon onClick={(event) => setOpen(true)}/>
                    <Menu
                        open={open}
                        onClose={(event) => setOpen(false)}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        sx={menuItemsStyle} // Apply the dark background to the menu items
                    >
                        {scrollLinks.map((link) => (
                            <CustomScrollLink
                                key={link.to}
                                to={link.to}
                                activeClass="active" // Add the activeClass prop
                            >
                                <MenuItem onClick={(event) => setOpen(false)}>
                                    {link.label}
                                </MenuItem>
                            </CustomScrollLink>
                        ))}
                    </Menu>
                </Toolbar>
            </AppBar>
        </Box>
    );
};

export default Navbar;
