import React from 'react';
import {Box, Typography} from '@mui/material';
import backgroundImage from '../assets/images/header-bg.jpg';

export const Start = () => {
    return (
        <Box
            id="Start"
            sx={{
                position: 'relative',
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'top center',
                height: '100vh',
                overflow: 'hidden',
            }}
        >
            <Box
                variant="h2" // You can adjust the variant and other styles as needed
                sx={{
                    position: 'absolute',
                    top: '70%', // Adjust vertical positioning as needed
                    left: '10%', // Adjust horizontal positioning as needed
                    transform: 'translateY(-50%)', // Center the text vertically
                    color: 'white',
                    fontWeight: 'bold', // Make the text bold
                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.9)', // Add a shadow
                    width: '50%', // Limit the text to half of the width
                    padding: '2em', // Adjust padding as needed
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                }}
            >
                <Typography variant="h1" sx={{fontWeight: 'bold', color: 'white'}}>
                    Produkcja, sprzedaż, wynajem kontenerów
                </Typography>
                <Typography variant="body1" sx={{fontWeight: 'bold', color: 'white'}}>
                    Kontenery biurowe, sanitarne, magazynowe i mieszkalne
                </Typography>
            </Box>
        </Box>
    );
};

export default Start;
